import 'react-responsive-carousel/lib/styles/carousel.min.css';

import React, {
    Component
} from 'react';

import {
    Carousel
} from 'react-responsive-carousel';
import IconInlineSVG from 'components/Card/IconInlineSVG.js';
import lokalise from 'utils/lokalise';
import moment from 'moment';

class Nearby extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: 320,
            height: '100%'
        }

		this.updateDimensions = this.updateDimensions.bind(this);
        this.carouselWrapper = React.createRef();
        this.resizes = 0; // we just need resizing to settle in the first load
    }

    componentDidMount() {
        this.updateDimensions();
    }

    componentDidUpdate() {
        if (this.resizes < 2) {
            this.updateDimensions();
            this.resizes += 1;
        }
    }

    getSortedPlaces = () => {
        // Sort places into pages
        let places = {};
        this.props.card.places.forEach((place) => {
            if (places[place.pageNumber]) {
                places[place.pageNumber].push(place)
            } else {
                places[place.pageNumber] = [place];
            }
        });

        let result = Object.entries(places).map(arr => arr[1]);

        return result;
    }

    getSlides(pages) {
        // console.log(this.props);
        return pages.map((page, i) => {
            return (<div key={i} className="Page"><Places page={page} timezone={this.props.screen.timezone} /></div>)
        });
    }

    // Recalculates the dimensions to fit perfectly into the column
    // This is because the plugin needs to adjust its slider in responsive design
    updateDimensions() {
        var width = this.state.width;
        if (this.carouselWrapper.current) {
            width = this.carouselWrapper.current.clientWidth;
        }
        // Width Limiter
        if (width > 400) width = 400;
        this.setState({
            width: width,
        })
    }

    render() {
        let pages = this.getSortedPlaces();
        const isAutoPlay = this.props.params.interactive === 'false' ? true : false;
        return (
            <div className="Content" ref={this.carouselWrapper}>
                {this.props.card.title ? <h2 className="Title">{this.props.card.title}</h2> : null}
                <div className="wrapper" style={{ width: this.state.width, height: this.state.height }}>
                    <Carousel
                        className="Slideshow"
                        emulateTouch={true}
                        useKeyboardArrows={true}
                        showArrows={true}
                        showStatus={false}
                        showThumbs={false}
                        autoPlay={isAutoPlay}
                        infiniteLoop={isAutoPlay}
                        interval={5000}
                    >
                        {this.getSlides(pages)}
                    </Carousel>
                </div>
            </div>
        )
    }
}

function Places(props) {
    return props.page.map((place, i) => {
        return (
            <div key={place.name + i} className="Row">
                <IconInlineSVG svg={place.icon} />
                <div className="Place">
                    <div className="PlaceName">{place.name}</div>
                    <div className="LocationName">{place.location}</div>

                    <Hours place={place} timezone={props.timezone} />
                </div>
                <div className="Navigate">
                    {place.travelMinutes ? <div className="WalkMin"><span>{place.travelMinutes}</span> {lokalise.getKey("ts3j")}</div> : null}
                </div>
            </div>
        )
    });
}


function Hours(props) {
    const place = props.place;

    if (!place.openTime || !place.closeTime) return null

    // console.log(place.currentTime, place.openTime[0], place.closeTime[0]);

    let now = moment(place.currentTime);

    let openTime = moment(place.openTime[0]);
    let closeTime = moment(place.closeTime[0]);

    var text = null;
    var textColor = null;
    // console.log(props.timezone);
    if (now.isBefore(openTime)) {
        if (openTime.diff(now, 'hours') <= 1) {
            text = lokalise.getKey("ts11e")
            textColor = "#3A9053";
        } else {
            text = lokalise.getKey("ts11f")
        }
    } else if (now.isBetween(openTime, closeTime)) {
        if (closeTime.diff(now, 'hours') <= 1) {
            text = lokalise.getKey("ts11g");
        } else {
            text = lokalise.getKey("ts11h")
            textColor = "#3A9053";
        }
    } else if (now.isAfter(closeTime)) {
        text = lokalise.getKey("ts11i")
        textColor = "#FF0000";
    }

    // console.log(now, openTime);

    const openTimes = <div className="OpenTimes">{lokalise.getKey("ts10z")} {openTime.format("h:mm a")} - {closeTime.format("h:mm a")}</div>

    return <div className="Hours">{openTimes} <div className="OpenMessage" style={{ color: textColor }}>{text}</div></div>;
}

export default Nearby
