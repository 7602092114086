import React, {
	useState
} from 'react';
import {
	isIOS,
	isMobileSafari,
} from 'react-device-detect';

import TabBarButton from 'components/TabBar/TabBarButton';
import config from 'config';
import lokalise from 'utils/lokalise';

export default function TabBar({ selectedTab,
	onNavBarButtonClick,
	isHub,
	params,
}) {
	const {
		barPosition,
		menu,
	} = params;

	const navButtons = config.modeFilterButtons;

	// "Other" button is now permanent
	// const hasShuttle = customer && customer.shuttleAgencyIds && customer.shuttleAgencyIds.length > 0;
	// if (!hasShuttle) {
	// 	delete navButtons.shuttle;
	// };

	const getModeFilterButtons = () => {
		let buttons = [];
		//allowedModes come in initials only so we have to transalte it for CMW
		const translatedParams = params.allowedModes?.split(',').map(mode => {
			return mode === 'r'
			? 'rail'
			: mode === 'b'
			? 'bus'
			: mode === 'c'
			? 'car'
			: mode === 'm'
			? 'micro'
			: mode === 'o'
			? 'other'
			: null
		}).filter(n => n)

		const defaultModes = [
			'all', //all
			'rail', //rail
			'bus', //bus
			'car', //car
			'micro', //micro
			'other' //other
		];

		let allowedModes = translatedParams  || defaultModes;

		if (isHub) {
			allowedModes = defaultModes;
		}

		for (let modeClass in navButtons) {
			if (modeClass === 'all' || allowedModes.includes(navButtons[modeClass].navIconName)) {
				buttons.push(
					<TabBarButton
						key={modeClass}
						title={lokalise.getKey(navButtons[modeClass].lokaliseTitleKey)}
						navIconName={navButtons[modeClass].navIconName}
						modeClass={modeClass}
						selectedTab={selectedTab}
						onNavBarButtonClick={onNavBarButtonClick}
					/>
				);
			}			
		}
		return buttons;
	}

	const getTripPlanButton = () => {
		return params && params.tripPlan && params.tripPlan === 'true' ? (
			<div className="TripPlanButton">
				<TabBarButton modeClass="tripplan" title={lokalise.getKey('nav_tripplan')} navIconName="tripplan" onNavBarButtonClick={onNavBarButtonClick} />
			</div>)
			: null;
	}

	const hasHeader = (menu !== undefined) ? true : false;

	let paddingBottom = 0;
	if (
		(isIOS)
		&& !isMobileSafari // 
		&& (window.innerHeight > 600 || window.innerWidth > 700) // Identify bar less iphones by dimensions
		&& barPosition === 'bottom'
	) {
		paddingBottom = 30;
	}

	// Offset top if nav bar is sitting below the Header or add padding to bottom for mobile
	const offsetStyle = barPosition === 'top' ? {
		marginTop: hasHeader ? 60 : 0
	} : {
		paddingBottom
	};


	return (
		<div className={`TabBar ${barPosition} ${params.tripPlan === 'true' ? 'hasTripPlan' : ''}`} style={offsetStyle}>
			<div role="tabpanel" className="TabBarControls">
				<div className="ModeFilterButtons">{getModeFilterButtons()}</div>
				{getTripPlanButton()}
			</div>
			<div className="bottom-spacer" style={{ height: paddingBottom }}></div>
		</div>
	);
}
