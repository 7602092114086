import CardBodyHeader from 'components/Card/CardBodyHeader';
import CardRows from 'components/Card/CardRows';
import React from 'react';
import StopAlerts from 'components/Card/StopAlerts';
import UnavailableAgencies from 'components/Card/UnavailableAgencies';
import config from 'config';

const TSCommons = require('TS-Commons/src/index');


export default function CardBody({ showCardScreen, card, opened, alerts, params, tapOpenCardView, screen }) {

	// @TODO Sorting needs to run on every update but not necessarily every render, maybe useEffect or do it in the updater util

	// Assign what collection in the JSON gets passed as cardRows objects
	let cardRows = [];
	if (card) {
		if (card.category === 'masstransit') {
			// TS Commons will group prediction objects into prediction groups
			cardRows = TSCommons.masstransit.getVehicleRows(card.predictions, {
				groupVehicles: true,
				groupingKey: 'groupId',
				predictionType: 'minutesAway',
				predictionKey: 'predictionMinutesAway',
				walkMinutes: card.walkMinutes,
				walkTimeMultiplier: 0.7,
				minimumPrediction: 0,
				maximumPrediction: card.maxPrediction || 99,
				sortBy: card.sortBy
			});
		} else if (card.category === 'dockless' || card.category === 'docked') {
			cardRows = card.locations?.sort((a, b) => a.walkMinutes - b.walkMinutes);
		} else if (card.agency.mode === 'floating') {
			cardRows = card.services;
		} else if (card.agency.mode === 'pointtopoint') {
			cardRows = card.locations?.sort((a, b) => a.walkMinutes - b.walkMinutes);
		} else if (card.type === 'parking') {
			cardRows = card.parking;
		} else if (card.type === 'flight') {
			cardRows = card.flights;
		}
	}

	// Set row limits
	let rowLimit = null;
	if (card) {
		if (card.category === 'masstransit') {
			rowLimit = config.defaultRowLimit.masstransit;
			// Wait time card increase limit
			if (card.items && card.items[0] && card.items[0].predictionType === 'waittime') {
				rowLimit = config.defaultRowLimit.waittime;
			}
		} else if (card.agency.mode === 'floating') {
			rowLimit = config.defaultRowLimit.floating;
		} else if (card.category === 'dockless') {
			rowLimit = config.defaultRowLimit.dockless;
		} else if (card.category === 'docked') {
			rowLimit = config.defaultRowLimit.docked;
		} else if (card.agency.mode === 'pointtopoint') {
			rowLimit = config.defaultRowLimit.carshare;
		}
	}
 
	return (
		<div className="CardBody">
			{card ?
				<> 
					{params.cardWalkTime !== 'false' ? card && <CardBodyHeader card={card} showCardScreen={showCardScreen} /> : <></>}
					<CardRows
						cardRows={cardRows}
						card={card}
						opened={opened}
						rowLimit={rowLimit}
						params={params}
						screen={screen}
						showCardScreen={showCardScreen}
					/>
					<StopAlerts
						card={card}
						alerts={alerts}
					/>
					<UnavailableAgencies
						card={card}
					/>
				</> : null}
		</div>
	)
}
