import React from 'react';
import de from 'languages/de.json';
import en from 'languages/en.json';
import es from 'languages/es.json';
import fr from 'languages/fr.json';
import fr_ca from 'languages/fr_CA.json'; // lokalise outputs these secondary l10n as uppercase
import fr_fr from 'languages/fr.json';
import it from 'languages/it.json';
import nl from 'languages/nl.json';
import pt from 'languages/pt.json';
import pt_pt from 'languages/pt_pt.json';
import tr from 'languages/tr.json';
import zh from 'languages/zh.json';

export default {

	// Must specify for import or it will default to english
	availableLanguages: {

		en,
		'en-en': en,
		'en-us': en,

		es,

		de,

		fr,
		fr_fr,
		fr_ca,
		'fr-fr': fr,
		'fr-ca': fr_ca,

		it,

		nl,
		'nl-nl': nl,

		pt,
		pt_pt,
		'pt_br': pt,
		'pt-br': pt,
		'pt_pt': pt_pt,
		'pt-pt': pt_pt,


		tr,


		zh,
		'zh_cn': zh,
		'zh-cn': zh
	},

	selectedLanguage: 'en',
	selectedLanguageKeys: en,

	reverseEnglishTranslationSet: {},

	setUserLanguage(langCode) {
		// Startup language
		langCode = langCode && langCode.toLowerCase();
		const [langCodeOnly] = langCode.split('-');
		const lang = this.availableLanguages[langCode] || this.availableLanguages[langCodeOnly];
		// ^ There might be a way to do the above with less code, but I don't know it off hand.
		if (lang) {

			const targetLanguage = lang;

			this.setupLokaliseHelper(langCode, targetLanguage);
		}
		// Otherwise check users browser parameters
		// var locale = navigator.language.split('-')[0];
		// if (Object.keys(this.translations).indexOf(locale) > -1) {
		// 	this.selectedLanguage = locale;
		// } else {
		// 	// Sometimes browser gives multiples
		// 	for (var i = 0; i < navigator.language.length; i++) {
		// 		locale = navigator.language[i].split('-')[0];
		// 		if (Object.keys(this.translations).indexOf(locale) > -1) {
		// 			this.selectedLanguage = locale;
		// 			break;
		// 		}
		// 	}
		// }
	},

	setupLokaliseHelper(langCode, keySet) {
		this.selectedLanguage = langCode;
		this.selectedLanguageKeys = keySet;
		if (langCode !== 'en') {
			this.setupReverseTranslationSet();
		}
	},

	// Takes the entire English set and reverses the key/value so we can translate to other languages
	// Example API returns "partly cloudy" and we find its en.json key which then matches to current lang 
	// @return ie: { "partly cloudy": "ts9q" } links us to { "ts9q": "Partiellement nuageux"}
	setupReverseTranslationSet() {
		let newSet = {};
		for (let key in en) {
			const phrase = en[key];
			// only strings
			if (typeof phrase === 'string') {
				newSet[phrase.toLowerCase()] = key;
			}
		}
		this.reverseEnglishTranslationSet = newSet;
	},

	/*
	 * Gets the identified translation key and allows for value replacement
	 * @param key: required "ts1a", "ts1b"....
	 * @param params: optional {"key1":value1, "key2":value2...}
	 * @param pluralKey: optional "one" or "other" to identify plural phrases
	 */
	getKey(key, params, pluralKey) {
		var languageObject = this.selectedLanguageKeys;
		if (languageObject) {
			var translation = languageObject[key];
			if (pluralKey) {
				translation = translation[pluralKey];
			}
			if (translation) {
				if (params) {
					translation = this.replaceComponents(translation, params);
				}
				return translation;
			}
		}
	},

	/*
	 * Replaces phrases with full components via arrays ie "{minutes} min {mode}"
	 * params can be both strings or HTML objects
	 */
	replaceComponents(translation, params) {
		const regex = /(\{\w+\})*/g;
		const pieces = translation.split(regex).filter(Boolean);
		// console.log(pieces);
		let compiled = pieces.map((piece) => {
			if (piece[0] === '{') {
				let key = piece.replace(/\{|\}/g, "");
				if (params[key]) {
					if (typeof params[key] === "string") {
						// Param is a solid string, add a white space
						return `${params[key]} `;
					} else {
						// Params can include HTML in this case
						return params[key];
					}
				}
			}
			return `${piece}`;
		});
		// Convert array into a series of fragments since it can include HTML
		compiled.map(comp => {
			return <React.Fragment>{comp}</React.Fragment>
		})
		return compiled;
	},

	/*
	 * Replaces strings
	 */
	replaceStrings(translation, params) {
		for (var property in params) {
			translation = translation.replace("{" + property + "}", params[property]);
		}
		return translation;
	},

	/*
	 * Takes a phrase in English like "westbound" and tries to return the current matching translation
	 */
	getReverseKey(englishPhrase) {
		if (englishPhrase && typeof englishPhrase === 'string') {
			const key = englishPhrase.toLowerCase();
			// console.log("Get translation", englishPhrase, this.selectedLanguageKeys[this.reverseEnglishTranslationSet[key]]);
			return this.selectedLanguageKeys[this.reverseEnglishTranslationSet[key]] || englishPhrase;
		}
	}
}
