import React, {
	Component,
} from 'react';

import CardNotice from 'components/Card/CardNotice.js';
import CardRow from 'components/Card/CardRow.js';
import ReactDOM from 'react-dom';
import SeeMore from 'components/Card/SeeMore.js';

class CardRows extends Component {

	constructor(props) {
		super(props);
		this.state = {
			concealRows: false, // Turn on feature only if needed see method
			showAll: false, // Toggles rows for row limited count and show all
			hiddenCount: 0, // Just text for the "Show X more options"
		};

		this.keyList = []; // used to track and avoid duplicate keys

		this.cardRowsDiv = React.createRef();

		this.updateVisibility = this.updateVisibility.bind(this);
		this.tapToggleCardExpand = this.tapToggleCardExpand.bind(this);
		this.getTrackingKey = this.getTrackingKey.bind(this);
		this.shouldConcealRows = this.shouldConcealRows.bind(this);
	}

	shouldConcealRows() {
		const {
			cardRows,
			rowLimit,
		} = this.props;
		if (!(this.props.params.interactive === 'false')) {
			const tooManyRows = cardRows && rowLimit && (cardRows.length > rowLimit);
			const hiddenCount = tooManyRows ? cardRows.length - rowLimit : 0;
			if (tooManyRows !== this.state.concealRows) {
				this.setState({
					concealRows: tooManyRows,
					hiddenCount
				});
			}
		}
	}

	componentDidMount() {
		this.shouldConcealRows();
	}

	componentDidUpdate(prevProps) {
		this.shouldConcealRows(); // Check again since card rows change on update

		if (this.state.concealRows) {
			// Now determine if we've clicked expand yet
			let shouldExpand = this.state.showAll;
			// We also let an external prop open these cards
			// if (this.props.opened) shouldExpand = true;

			// Wrapping setTimeout and RAF per https://stackoverflow.com/questions/26556436/react-after-render-code
			let callback = () => {
				this.updateVisibility(shouldExpand);
			};
			setTimeout(function () {
				requestAnimationFrame(callback);
			})
		}
	}


	tapToggleCardExpand() {
		this.setState({
			showAll: !this.state.showAll
		});
		this.updateVisibility(!this.state.showAll);
	}

	/*
	 * Changes the visibility of row DOM element
	 * ON EVERY RENDER in order to capture data/row changes
	 * Sets a CSS height of either 0 or the measured height
	 */
	updateVisibility(shouldExpand) {
		let rowLimit = this.props.rowLimit;

		let heightClosed = 0;
		let heightOpened = 0;

		try {
			const node = ReactDOM.findDOMNode(this);
			if (node && node instanceof HTMLElement) {
				const rows = node.querySelectorAll(".CardRow");

				rows.forEach((row, i) => {
					if (row && row.offsetHeight) {
						let heightPx = row.offsetHeight;
						if (i < rowLimit - 1) {
							heightClosed += heightPx;
							heightOpened += heightPx;
						} else if (i === rowLimit - 1) {
							heightClosed += heightPx;
							heightOpened += heightPx;
							row.className = "CardRow CardRow-lastabovefold";
						} else {
							heightOpened += heightPx;
						}
					}
				});

				// Dont try to set heights if nothing was calculated
				if (!heightClosed || !heightOpened) return

				// console.log("Update Visibility: ", rowLimit, showAll, heightClosed, heightOpened);
				if (this.cardRowsDiv.current) {
					// console.log(this.cardRowsDiv.current.style);
					let height = shouldExpand ? heightOpened : heightClosed;
					this.cardRowsDiv.current.style.height = `${height}px`
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

	/*
	 * A tracking key is required for React to figure out what needs to be updated or moved in the rowlist
	 */
	getTrackingKey(row, i) {
		let availableId = 'Card'; // Represents a fallback key

		if (row) {
			let shortRoute = row.short_route || "";
			let fullRoute = row.full_route || "";
			let destination = row.destination || "";

			let availableId = `${shortRoute}${fullRoute}${destination}`;
			if (!availableId) {
				availableId = `${row.name}`
			}

			availableId = availableId.replace(/\s|[^\w]/g, '');

			// One last validation
			if (!availableId || availableId.length < 2) availableId = 'TransitCard';
		}

		// Sometimes we cannot avoid duplicates so append the index number
		if (this.keyList.indexOf(availableId) !== -1) availableId = `${availableId}${i}`;
		this.keyList.push(availableId);

		return `key${availableId}`
	}

	render() {
		const {
			cardRows,
			card,
			opened,
			screen,
			showCardScreen,
		} = this.props;

		if (!cardRows) return null;
		return (
			<section className={`CardRows ${this.state.showAll ? 'expanded' : 'collapsed'}`}>
				<div role="table" className="RowArea" ref={this.cardRowsDiv}>
					<Rows cardRows={cardRows} card={card} getTrackingKey={this.getTrackingKey} opened={opened} timeDisplay={this.props.params.timeDisplay} groupTransitRows={this.props.params.groupTransitRows} sortOrder={this.props.params.sortOrder} interactive={this.props.params.interactive} screen={screen} />
				</div>
				{this.state.concealRows ? <SeeMore showCardScreen={showCardScreen} hiddenCount={this.state.hiddenCount} tapToggleCardExpand={this.tapToggleCardExpand} showAll={this.state.showAll} /> : null}
			</section>
		)
	}
}

/*
 * Returns renderable components based on cardRow prop data (transformed in parent component)
 */
function Rows(props) {
	const {
		cardRows,
		card,
		getTrackingKey,
		opened,
		timeDisplay,
		groupTransitRows,
		sortOrder,
		interactive,
		screen,
	} = props;

	const {
		agency,
		agencies,
		items,
		category,
	} = card;



	// Display "nothing here" messages
	if (!cardRows || cardRows.length === 0) {
		return (<CardNotice {...props} />);
	}

	//Check for sortOrder, only if there is no screen value. If there is a screen value, user is in a hub zone and all custom sorting is nullified
	if (cardRows.length > 0 && (sortOrder === 'alphanumeric' || sortOrder === 'alphanumericByMode') && screen === undefined) {

		cardRows.sort(function (a, b) {

			var textA = a[0]?.shortRoute.toLowerCase();
			var textB = b[0]?.shortRoute.toLowerCase();

			// if we're comparing number to number, do number sorting
			if (!isNaN(textA) && !isNaN(textB)) {
				var numA = parseInt(textA);
				var numB = parseInt(textB);
				return (numA < numB) ? -1 : (numA > numB) ? 1 : 0;
			}

			// else sort as strings
			return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
		});
	}

	// Otherwise proceed to outputting rows
	return cardRows.map((row, i) => {
		const {
			agencyId
		} = row;

		// console.log("row", row);
		if (category === 'masstransit') {
			const firstVehicle = row[0];
			const key = getTrackingKey(firstVehicle, i);
			// Masstransit, the overall row is represented by the first vehicle
			// The remaining prediction data (duplicates) are passed as vehicleGroup
			// row.map(r => console.log(r))
			if (groupTransitRows === 'false') {
				return row.map((r, i) => {
					return <CardRow key={key + i} card={card} rowAgency={agency} rowData={r} opened={opened} vehicleGroup={r} datasources={items} timeDisplay={timeDisplay} interactive={interactive} />;
				})
			}
			else {
				return <CardRow key={key} card={card} rowAgency={agency} rowData={firstVehicle} opened={opened} vehicleGroup={row} datasources={items} timeDisplay={timeDisplay} interactive={interactive} />;
			}
		}

		// All other cards, determine which agency should apply to the row
		const key = getTrackingKey(row, i);
		const rowAgency = agencies ? agencies[agencyId] : agency;
		return <CardRow key={key} card={card} rowAgency={rowAgency} rowData={row} opened={opened} interactive={interactive} />;
	});

}

export default CardRows;
